@charset "UTF-8";
/* Importing Bootstrap SCSS file. */
.br-default {
  --br-font-size: 26px;
}
.br-default .br-units {
  align-items: center;
}
.br-default .br-unit-inner:before {
  content: "★";
  color: var(--br-inactive-color);
  width: 100%;
}
.br-default .br-active:before {
  color: var(--br-active-color);
}
.br-default .br-fraction:after {
  color: var(--br-active-color);
  content: "★";
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

a {
  color: #19b133;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #12a32a;
}

.btn:focus,
button:focus {
  box-shadow: none;
  outline: none;
}

.btn {
  border: 0;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  border-radius: 8px;
}

.btn.btn-primary {
  background: #19b133;
  color: #fff;
}

.btn.btn-default {
  background: #fff;
  border: 1px solid #d0d5dd;
}

.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #19b133;
  border-color: #19b133;
  outline: none !important;
  box-shadow: none !important;
}

input.form-control, input#country-search-box {
  height: 45px;
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 20px;
  border-color: #ced4da !important;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.form-control, #country-search-box {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.settings .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.settings .custom-switch {
  padding-left: 2.25rem;
}

.settings .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.settings input[type=checkbox],
.settings input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

.settings .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.settings .custom-control-label::before {
  position: absolute;
  top: 0;
  left: -1.5rem;
  display: block;
  width: 60px;
  height: 35px;
  pointer-events: none;
  content: "";
  background-color: #616161;
  border: #adb5bd solid 1px;
  border-radius: 30px;
}

.settings .custom-control-label::before,
.settings .custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.settings .custom-switch .custom-control-label::before {
  left: -4.25rem;
}

.settings .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #19b133;
  background-color: #19b133;
}

.settings .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.settings .custom-switch .custom-control-label::after {
  top: calc(0.3rem + 0px);
  left: calc(-3.8rem - 2px);
  width: 26px;
  height: 26px;
  background-color: #e6e6e6;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 57px;
}

.settings .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #e6e6e6;
  transform: translateX(1.55rem);
}

.filter-result .tabs-area .mat-tab-label .mat-tab-label-content {
  font-size: 20px;
  margin-right: 40px;
}

.auth {
  padding: 40px 50px;
}

.auth-box {
  width: 400px;
  margin: 0 auto;
  padding: 80px 0px 20px;
  padding-right: 50px;
}

.auth-box form {
  margin-top: 50px;
}

.auth-box .passwordWithIcon {
  position: relative;
}

.auth-box .passwordWithIcon .mat-icon {
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: 10px;
  color: #667085;
  z-index: 1;
}

.auth-box .btn {
  width: 100%;
}

.auth-box .google-icon {
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.auth-bottom-text {
  text-align: center;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

vertical-layout-1 #main > .container-2 {
  position: relative;
  padding: 30px 0px;
}

img {
  max-width: 100%;
}

vertical-layout-1 toolbar {
  padding: 30px 15px;
}

.mat-ripple-element {
  width: 0 !important;
  height: auto !important;
}

.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

.mat-tab-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.manager .mat-tab-labels,
.group-class-detail .tabs-area .mat-tab-labels,
.private-class-detail .tabs-area .mat-tab-labels,
.private-finished-class-detail .mat-tab-labels,
.filter-result .tabs-area .mat-tab-labels {
  display: flex;
  justify-content: flex-start;
}

.manager .mat-tab-label-content {
  font-size: 23px;
  font-weight: 600;
  margin-right: 50px;
  margin-bottom: 10px;
}

.private-class-detail .tabs-area .mat-tab-label-content,
.group-class-detail .tabs-area .mat-tab-label-content,
.private-finished-class-detail .tabs-area .mat-tab-label-content {
  font-size: 22px;
  font-weight: 600;
  margin-right: 40px;
  margin-bottom: 10px;
}

.private-class-detail .tabs-area .mat-tab-label-content,
.private-finished-class-detail .mat-tab-label-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.private-class-detail .tabs-area .mat-tab-label-content span,
.private-finished-class-detail .tabs-area .mat-tab-label-content span {
  color: #7a7b7a;
  font-size: 17px;
}

.mat-tab-label {
  height: unset !important;
  padding: 5px 0 !important;
  min-width: auto !important;
  opacity: 1 !important;
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 700 !important;
}

.mat-tab-label-active {
  color: #19b133 !important;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

filter-dialog {
  position: absolute;
  display: block;
  width: 100%;
  height: 100vh;
  z-index: 11111111111111112;
  left: 0;
  top: 0;
}

.profile-btn {
  position: absolute;
  top: 35px;
  right: 40px;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  z-index: 111;
  border: 1px solid #ccc;
  border-radius: 50px;
}

.profile-btn img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.main {
  width: calc(100% - 100px);
  padding: 50px 10px;
}

/* Side bar */
.side-bar {
  background: #323232;
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0 40px;
  border-radius: 25px;
  margin: 0 15px;
}

.side-bar .side-logo {
  margin-bottom: 60px;
}

.side-bar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-bar li {
  margin-top: 48px;
}

.side-bar li a {
  cursor: pointer;
}

.side-bar .divider {
  display: block;
  border-top: 1px solid #fff;
  width: 50px;
  margin-top: 60px;
}

.messages ::-webkit-scrollbar,
.calendar-box ::-webkit-scrollbar {
  width: 0px !important;
}

.messages .mat-tab-body-content {
  overflow: auto !important;
}

.profile .profile-box .profile-body .tabs-area .mat-tab-labels {
  margin-bottom: 0;
}

.profile .profile-box .profile-body .tabs-area .mat-tab-header {
  border: 1px solid #ccc;
  padding: 5px 15px;
  border-radius: 10px;
  background: #f7f7f7;
  margin-top: 20px;
  margin-bottom: 40px;
}

.profile .profile-box .profile-body .tabs-area .mat-tab-label .mat-tab-label-content {
  font-size: 20px;
}

.side-bar li a svg path {
  stroke: #fff;
}

.side-bar li a.active svg path {
  stroke: #19b133;
}

.side-bar li a.fill svg path {
  fill: #19b133;
}

.fa-exclamation-triangle {
  color: #cf0b0b;
}

.text-green {
  color: #19b133;
}

.Yes {
  background-color: #19b133 !important;
  color: #fff !important;
}

.No {
  background-color: #d60727 !important;
  color: #fff !important;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

body {
  font-family: "Circular Std", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

a {
  color: #19b133;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #12a32a;
}

.btn:focus,
button:focus {
  box-shadow: none;
  outline: none;
}

.btn {
  border: 0;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  border-radius: 8px;
}

.btn.btn-primary {
  background: #19b133;
  color: #fff;
}

.btn.btn-default {
  background: #fff;
  border: 1px solid #d0d5dd;
}

.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #19b133;
  border-color: #19b133;
  outline: none !important;
  box-shadow: none !important;
}

input.form-control, input#country-search-box {
  height: 45px;
  outline: none !important;
  box-shadow: none !important;
  margin-bottom: 20px;
  border-color: #ced4da !important;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.form-control, #country-search-box {
  outline: none !important;
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.settings .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.settings .custom-switch {
  padding-left: 2.25rem;
}

.settings .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.settings input[type=checkbox],
.settings input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

.settings .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.settings .custom-control-label::before {
  position: absolute;
  top: 0;
  left: -1.5rem;
  display: block;
  width: 60px;
  height: 35px;
  pointer-events: all;
  content: "";
  background-color: #616161;
  border: #adb5bd solid 1px;
  border-radius: 30px;
}

.settings .custom-control-label::before,
.settings .custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.settings .custom-switch .custom-control-label::before {
  left: -4.25rem;
}

.settings .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #19b133;
  background-color: #19b133;
}

.settings .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.settings .custom-switch .custom-control-label::after {
  top: calc(0.3rem + 0px);
  left: calc(-3.8rem - 2px);
  width: 26px;
  height: 26px;
  background-color: #e6e6e6;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 57px;
}

.settings .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #e6e6e6;
  transform: translateX(1.55rem);
}

.auth {
  padding: 40px 50px;
}

.auth-box {
  width: 400px;
  margin: 0 auto;
  padding: 80px 0px 20px;
  padding-right: 50px;
}

.auth-box form {
  margin-top: 50px;
}

.auth-box .passwordWithIcon {
  position: relative;
}

.auth-box .passwordWithIcon .mat-icon {
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: 10px;
  color: #667085;
  z-index: 1;
}

.auth-box .btn {
  width: 100%;
}

.auth-box .google-icon {
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.auth-bottom-text {
  text-align: center;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

vertical-layout-1 #main > .container-2 {
  position: relative;
  padding: 30px 0px;
}

@media (max-width: 600px) {
  vertical-layout-1 #main > .container-2 {
    padding: 0;
  }
}
img {
  max-width: 100%;
}

vertical-layout-1 toolbar {
  padding: 30px 15px;
}

.mat-ripple-element {
  width: 0 !important;
  height: auto !important;
}

.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

.mat-tab-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.manager .mat-tab-labels,
.group-class-detail .tabs-area .mat-tab-labels,
.private-class-detail .tabs-area .mat-tab-labels {
  display: flex;
  justify-content: flex-start;
}

.manager .mat-tab-label-content {
  font-size: 23px;
  font-weight: 600;
  margin-right: 50px;
  margin-bottom: 10px;
}

.private-class-detail .tabs-area .mat-tab-label-content,
.group-class-detail .tabs-area .mat-tab-label-content {
  font-size: 22px;
  font-weight: 600;
  margin-right: 40px;
  margin-bottom: 10px;
}

.private-class-detail .tabs-area .mat-tab-label-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.private-class-detail .tabs-area .mat-tab-label-content span {
  color: #7a7b7a;
  font-size: 17px;
}

.mat-tab-label {
  height: unset !important;
  padding: 5px 0 !important;
  min-width: auto !important;
  opacity: 1 !important;
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 700 !important;
}

.mat-tab-label-active {
  color: #19b133 !important;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.profile-btn {
  position: absolute;
  top: 35px;
  right: 40px;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  z-index: 11111;
  border: 1px solid #ccc;
  border-radius: 50px;
}

.profile-btn img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.main {
  width: calc(100% - 100px);
  padding: 50px 10px;
}

@media (max-width: 600px) {
  .main {
    width: 100%;
    padding: 50px 10px;
  }
}
/* Side bar */
.side-bar {
  background: #323232;
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0 40px;
  border-radius: 25px;
  margin: 0 15px;
}

.side-bar .side-logo {
  margin-bottom: 60px;
}

.side-bar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-bar li {
  margin-top: 30px;
}

.side-bar li a {
  cursor: pointer;
}

.side-bar .divider {
  display: block;
  border-top: 1px solid #fff;
  width: 50px;
  margin-top: 60px;
}

.messages ::-webkit-scrollbar,
.calendar-box ::-webkit-scrollbar {
  width: 0px !important;
}

.messages .mat-tab-body-content {
  overflow: auto !important;
}

.profile .profile-box .profile-body .tabs-area .mat-tab-labels {
  margin-bottom: 0;
}

.profile .profile-box .profile-body .tabs-area .mat-tab-header {
  border: 1px solid #ccc;
  padding: 5px 15px;
  border-radius: 10px;
  background: #f7f7f7;
  margin-top: 20px;
  margin-bottom: 40px;
}

.profile .profile-box .profile-body .tabs-area .mat-tab-label .mat-tab-label-content {
  font-size: 20px;
}

.mobile .profile .profile-box .profile-body .tabs-area .mat-tab-label .mat-tab-label-content {
  font-size: 14px !important;
}

.side-bar li a svg path {
  stroke: #fff;
}

.side-bar li a.active svg path {
  stroke: #19b133;
}

.side-bar li a.fill svg path {
  fill: #19b133;
}

.fa-exclamation-triangle {
  color: #cf0b0b;
}

.text-green {
  color: #19b133;
}

.Yes {
  background-color: #19b133 !important;
  color: #fff !important;
}

.No {
  background-color: #d60727 !important;
  color: #fff !important;
}